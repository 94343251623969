import React, { useEffect } from 'react';
import tw from 'twin.macro';
import Layout from 'templates/Layout';
import { Section, Inner, PageTitle } from 'components';
import Link from 'components/Link';

function ConfirmationPage({ location }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && location?.state) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newStartupFunnelStarted',
        request_type: 'Get listed',
      });
    }
  }, [location]);

  return (
    <Layout>
      <Section tw="sm:pt-80">
        <Inner css={tw`sm:max-w-3xl`}>
          <PageTitle tw="max-w-3xl mb-5">Thank you for reaching out to us!</PageTitle>
          <p>
            We look forward to getting to know you and have received all your information and will
            get back to you as soon as possible.
            <br />
            <br />
            Talk to you soon.
          </p>

          <div css={tw`underline mt-12 lg:flex gap-4`}>
            <Link href="/get-listed">Submit another startup</Link>
            <Link href="/">To homepage</Link>
          </div>
        </Inner>
      </Section>
    </Layout>
  );
}

export default ConfirmationPage;
